import { io, Socket } from 'socket.io-client'
import { uuid, epochNow } from './utils'
import { configurationStore, notificationStore } from './store'
import SuprSendInbox from '.'
import { IStore, IRemoteNotification } from './types'

export default class ApiClient {
  socket: Socket
  config: SuprSendInbox

  constructor(config: SuprSendInbox) {
    this.config = config
    const configStore = configurationStore.getState()

    this.socket = io(configStore.socketUrl, {
      transports: ['websocket'],
      auth: {
        subscriber_id: this.config.subscriberId,
        distinct_id: this.config.distinctId,
        authorization: `${this.config.workspaceKey}:${uuid()}`,
        tenant_id: this.config.tenantId
      },
      reconnectionAttempts: 25,
      reconnectionDelay: 5000,
      reconnectionDelayMax: 10000
    })
    this._initializeSocketEvents()
  }

  private orderNotifications(
    newNotification: IRemoteNotification,
    oldNotifications: IRemoteNotification[]
  ) {
    // if pinned notification add new notification at start else at end of pinned notifications
    if (newNotification.is_pinned) {
      return [newNotification, ...oldNotifications]
    } else {
      let addedNotification = false
      let notifications: IRemoteNotification[] = []
      oldNotifications.forEach((notification) => {
        if (notification.is_pinned) {
          notifications.push(notification)
        } else {
          if (addedNotification) {
            notifications.push(notification)
          } else {
            notifications.push(newNotification)
            notifications.push(notification)
            addedNotification = true
          }
        }
      })
      if (!addedNotification) {
        return [...oldNotifications, newNotification]
      }
      return notifications
    }
  }

  private _initializeSocketEvents() {
    this.socket.on('new_notification', async (data) => {
      if (!data.n_id) return
      setTimeout(async () => {
        try {
          const response = await this.getNotificationDetails(data.n_id)
          const resData = await response.json()

          const notifStore = notificationStore.getState()
          if (this.config.stores) {
            let overallCountUpdate = false
            for (let store of this.config.stores) {
              const storeData = notifStore.stores[store.storeId]
              if (this._notificationBelongToStore(resData.data, store)) {
                overallCountUpdate = true
                storeData.notifications = this.orderNotifications(
                  resData.data,
                  storeData.notifications
                )
                storeData.unseenCount += 1
              }
            }
            if (overallCountUpdate) {
              notifStore.unseenCount += 1
              this.config.emitter.emit('new_notification', resData.data)
            }
            notificationStore.setState({
              unseenCount: notifStore.unseenCount,
              stores: { ...notifStore.stores }
            })
          } else {
            if (!notifStore.activeStoreId) return
            let defaultStore = notifStore.stores[notifStore.activeStoreId]
            notifStore.unseenCount += 1
            defaultStore.unseenCount += 1
            defaultStore.notifications = this.orderNotifications(
              resData.data,
              defaultStore.notifications
            )
            notificationStore.setState({
              unseenCount: notifStore.unseenCount,
              stores: { ...notifStore.stores }
            })
            this.config.emitter.emit('new_notification', resData.data)
          }

          this.config.emitter.emit('sync_notif_store')
        } catch (e) {
          console.log('SuprSend: error in new_notification event', e)
        }
      }, 1000)
    })

    this.socket.on('notification_updated', async (data) => {
      if (!data.n_id) return
      setTimeout(async () => {
        try {
          const apiCalls = await Promise.allSettled([
            this.getNotificationDetails(data.n_id),
            this.getNotificationsCount()
          ])

          const notifStore = notificationStore.getState()
          let activeStoreId = notifStore.activeStoreId

          if (apiCalls[0].status === 'fulfilled') {
            const response = apiCalls[0].value
            const resData = await response.json()
            const newNotificationData = resData.data

            if (!activeStoreId) return

            let activeStore = notifStore.stores[activeStoreId]
            const activeStoreQueryData = this.config.stores?.find(
              (store) => store.storeId === activeStoreId
            )

            if (data?.type === 'archive') {
              if (activeStoreQueryData?.query?.archived) {
                if (
                  this._notificationBelongToStore(
                    newNotificationData,
                    activeStoreQueryData
                  )
                ) {
                  notifStore.stores[activeStoreId].notifications = [
                    newNotificationData,
                    ...activeStore.notifications
                  ]
                }
              } else {
                notifStore.stores[activeStoreId].notifications =
                  activeStore.notifications.filter(
                    (notification) => notification.n_id !== data.n_id
                  )
              }
            } else {
              notifStore.stores[activeStoreId].notifications =
                activeStore.notifications.map((notification) =>
                  notification.n_id === data.n_id ? resData.data : notification
                )
            }

            notificationStore.setState({ stores: { ...notifStore.stores } })
          }

          if (apiCalls[1].status === 'fulfilled') {
            const countResponse = apiCalls[1].value
            const countData = await countResponse.json()

            notificationStore.setState((prevState) => {
              for (let storeId in prevState.stores) {
                const store = prevState.stores[storeId]
                store.unseenCount = countData[storeId] || 0
              }
              return {
                unseenCount: countData.ss_bell_count,
                stores: { ...prevState.stores }
              }
            })
          }

          this.config.emitter.emit('sync_notif_store')
        } catch (e) {
          console.log('SuprSend: error in notification_updated event', e)
        }
      }, 1000)
    })

    this.socket.on('update_badge', async () => {
      notificationStore.setState({ unseenCount: 0 })
      this.config.emitter.emit('sync_notif_store')
    })

    this.socket.on('mark_all_read', async () => {
      const notifStore = notificationStore.getState()
      const clickedOn = epochNow()

      for (let storeId in notifStore.stores) {
        const store = notifStore.stores[storeId]
        store.notifications.forEach((notification: IRemoteNotification) => {
          if (!notification.seen_on) {
            notification.seen_on = clickedOn
          }
        })
        store.unseenCount = 0
      }
      notificationStore.setState({ stores: notifStore.stores })
      this.config.emitter.emit('sync_notif_store')
    })
  }

  private _notificationBelongToStore(notification: any, store?: IStore) {
    const notifRead = !!notification.seen_on
    const notifArchived = notification.archived
    const notifTags: string[] | undefined = notification.tags
    const notifCategory: string = notification.n_category

    const storeRead = store?.query?.read
    const storeArchived = store?.query?.archived
    const storeTags = store?.query?.tags
    const storeCategories = store?.query?.categories

    const sameRead = !storeRead || notifRead === storeRead
    const sameArchived = !storeArchived || notifArchived === storeArchived
    let sameTags, sameCategory

    if (Array.isArray(storeTags) && storeTags.length > 0) {
      storeTags.forEach((tag) => {
        if (notifTags?.includes(tag)) {
          sameTags = true
        }
      })
    } else {
      sameTags = true
    }

    if (Array.isArray(storeCategories) && storeCategories.length > 0) {
      if (storeCategories.includes(notifCategory)) {
        sameCategory = true
      }
    } else {
      sameCategory = true
    }

    return sameRead && sameTags && sameCategory && sameArchived
  }

  private _getStoreQueryString(store: IStore) {
    const query = store?.query

    let tags = query?.tags || []
    let categories = query?.categories || []
    const read = query?.read
    const archived = query?.archived

    return {
      store_id: store.storeId,
      query: {
        read,
        archived,
        tags: { or: tags },
        categories: { or: categories }
      }
    }
  }

  private _getStoresQueryString() {
    const stores = this.config.stores

    let apiStores = stores?.map((store) => {
      return this._getStoreQueryString(store)
    })

    return apiStores
  }

  getNotificationsCount() {
    const { apiUrl } = configurationStore.getState()
    let route = `/notification_count/?subscriber_id=${this.config.subscriberId}&distinct_id=${this.config.distinctId}&tenant_id=${this.config.tenantId}`

    if (this.config.stores) {
      const storedFilter = this._getStoresQueryString()
      route += `&stores=${encodeURIComponent(JSON.stringify(storedFilter))}`
    }

    return fetch(`${apiUrl}${route}`, {
      method: 'GET',
      headers: {
        Authorization: `${this.config.workspaceKey}:${uuid()}`
      }
    })
  }

  getNotifications(
    store: IStore | undefined,
    page_no: number,
    page_size: number = 20,
    before: number
  ) {
    const { apiUrl } = configurationStore.getState()
    let route = `/notifications/?subscriber_id=${this.config.subscriberId}&distinct_id=${this.config.distinctId}&tenant_id=${this.config.tenantId}&page_no=${page_no}&page_size=${page_size}&before=${before}`

    if (store) {
      const storedFilter = this._getStoreQueryString(store)
      route += `&store=${encodeURIComponent(JSON.stringify(storedFilter))}`
    }

    return fetch(`${apiUrl}${route}`, {
      method: 'GET',
      headers: {
        Authorization: `${this.config.workspaceKey}:${uuid()}`
      }
    })
  }

  getNotificationDetails(notification_id: string) {
    const { apiUrl } = configurationStore.getState()
    const route = `/notification/${notification_id}/?subscriber_id=${this.config.subscriberId}&distinct_id=${this.config.distinctId}&tenant_id=${this.config.tenantId}`

    return fetch(`${apiUrl}${route}`, {
      method: 'GET',
      headers: {
        Authorization: `${this.config.workspaceKey}:${uuid()}`
      }
    })
  }

  markBellClicked() {
    const { apiUrl } = configurationStore.getState()
    const route = '/bell-clicked/'
    const body = JSON.stringify({
      time: epochNow(),
      distinct_id: this.config.distinctId,
      subscriber_id: this.config.subscriberId,
      tenant_id: this.config.tenantId
    })

    return fetch(`${apiUrl}${route}`, {
      method: 'POST',
      body,
      headers: {
        Authorization: `${this.config.workspaceKey}:${uuid()}`,
        'Content-Type': 'application/json'
      }
    })
  }

  markAllRead() {
    const { apiUrl } = configurationStore.getState()
    const route = '/mark-all-read/'
    const body = JSON.stringify({
      time: epochNow(),
      distinct_id: this.config.distinctId,
      subscriber_id: this.config.subscriberId,
      tenant_id: this.config.tenantId
    })

    return fetch(`${apiUrl}${route}`, {
      method: 'POST',
      body,
      headers: {
        Authorization: `${this.config.workspaceKey}:${uuid()}`,
        'Content-Type': 'application/json'
      }
    })
  }

  markNotificationClicked(id: string) {
    const { collectorApiUrl } = configurationStore.getState()
    const body = {
      event: '$notification_clicked',
      env: this.config.workspaceKey,
      $insert_id: uuid(),
      $time: epochNow(),
      properties: { id }
    }

    return fetch(`${collectorApiUrl}/event/`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: `${this.config.workspaceKey}:`,
        'Content-Type': 'application/json'
      }
    })
  }

  markNotificationRead(id: string) {
    const { apiUrl } = configurationStore.getState()

    return fetch(`${apiUrl}/notification/${id}/action`, {
      method: 'POST',
      body: JSON.stringify({
        action: 'read',
        distinct_id: this.config.distinctId,
        subscriber_id: this.config.subscriberId
      }),
      headers: {
        Authorization: `${this.config.workspaceKey}:`,
        'Content-Type': 'application/json'
      }
    })
  }

  markNotificationUnRead(id: string) {
    const { apiUrl } = configurationStore.getState()

    return fetch(`${apiUrl}/notification/${id}/action`, {
      method: 'POST',
      body: JSON.stringify({
        action: 'unread',
        distinct_id: this.config.distinctId,
        subscriber_id: this.config.subscriberId
      }),
      headers: {
        Authorization: `${this.config.workspaceKey}:`,
        'Content-Type': 'application/json'
      }
    })
  }

  markNotificationArchive(id: string) {
    const { apiUrl } = configurationStore.getState()

    return fetch(`${apiUrl}/notification/${id}/action`, {
      method: 'POST',
      body: JSON.stringify({
        action: 'archive',
        distinct_id: this.config.distinctId,
        subscriber_id: this.config.subscriberId
      }),
      headers: {
        Authorization: `${this.config.workspaceKey}:`,
        'Content-Type': 'application/json'
      }
    })
  }
}
