import React from 'react'

const PinnedNotificationIcon = ({ style }) => {
  const height = style?.height || 13
  const width = style?.width || 13
  const color = style?.color || '#DC6803'

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.58984 2.9305C7.38193 2.44536 7.27797 2.2028 7.10998 2.09261C6.96308 1.99626 6.78408 1.96179 6.6119 1.99669C6.415 2.03661 6.22839 2.22321 5.85518 2.59643L2.42642 6.02519C2.0532 6.39841 1.86659 6.58502 1.82668 6.78191C1.79177 6.95409 1.82624 7.13309 1.9226 7.28C2.03278 7.44799 2.27535 7.55194 2.76048 7.75986L4.42339 8.47253C4.49393 8.50277 4.5292 8.51788 4.56228 8.53678C4.59166 8.55356 4.61972 8.57256 4.64622 8.59361C4.67605 8.6173 4.70318 8.64444 4.75745 8.69871L5.79805 9.7393C5.88293 9.82418 5.92537 9.86662 5.95909 9.91498C5.98903 9.9579 6.0138 10.0042 6.03291 10.0529C6.05444 10.1078 6.06621 10.1666 6.08975 10.2844L6.57835 12.7273C6.70532 13.3622 6.76881 13.6796 6.93618 13.8281C7.08198 13.9575 7.27711 14.0166 7.47019 13.9898C7.69183 13.9591 7.92074 13.7302 8.37855 13.2724L13.1024 8.54856C13.5602 8.09075 13.7891 7.86185 13.8198 7.64021C13.8466 7.44713 13.7875 7.252 13.6581 7.10619C13.5096 6.93882 13.1922 6.87534 12.5573 6.74836L10.1143 6.25977C9.99663 6.23622 9.93777 6.22445 9.88289 6.20293C9.83418 6.18382 9.78788 6.15904 9.74496 6.12911C9.69661 6.09538 9.65416 6.05294 9.56928 5.96806L8.52869 4.92747C8.47442 4.8732 8.44729 4.84606 8.42359 4.81624C8.40255 4.78974 8.38355 4.76168 8.36676 4.73229C8.34787 4.69922 8.33275 4.66395 8.30252 4.5934L7.58984 2.9305Z'
        fill={color}
      />
      <path
        d='M10.7405 10.9105L14.5117 14.6817M8.52869 4.92747L9.56928 5.96806C9.65416 6.05294 9.69661 6.09538 9.74496 6.12911C9.78788 6.15904 9.83418 6.18382 9.88289 6.20293C9.93777 6.22445 9.99663 6.23622 10.1143 6.25977L12.5573 6.74836C13.1922 6.87534 13.5096 6.93882 13.6581 7.10619C13.7875 7.252 13.8466 7.44713 13.8198 7.64021C13.7891 7.86185 13.5602 8.09075 13.1024 8.54856L8.37855 13.2724C7.92074 13.7302 7.69183 13.9591 7.47019 13.9898C7.27711 14.0166 7.08198 13.9575 6.93618 13.8281C6.76881 13.6796 6.70532 13.3622 6.57835 12.7273L6.08975 10.2844C6.06621 10.1666 6.05444 10.1078 6.03291 10.0529C6.0138 10.0042 5.98903 9.9579 5.95909 9.91498C5.92537 9.86662 5.88293 9.82418 5.79805 9.7393L4.75745 8.69871C4.70318 8.64444 4.67605 8.6173 4.64622 8.59361C4.61972 8.57256 4.59166 8.55356 4.56228 8.53678C4.5292 8.51788 4.49393 8.50277 4.42339 8.47253L2.76048 7.75986C2.27535 7.55194 2.03278 7.44799 1.9226 7.28C1.82624 7.13309 1.79177 6.95409 1.82668 6.78191C1.86659 6.58502 2.0532 6.39841 2.42642 6.02519L5.85517 2.59643C6.22839 2.22321 6.415 2.03661 6.6119 1.99669C6.78408 1.96179 6.96308 1.99626 7.10998 2.09261C7.27797 2.2028 7.38193 2.44536 7.58984 2.9305L8.30252 4.5934C8.33275 4.66395 8.34787 4.69922 8.36676 4.73229C8.38355 4.76168 8.40255 4.78974 8.42359 4.81624C8.44729 4.84606 8.47442 4.8732 8.52869 4.92747Z'
        stroke={color}
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default PinnedNotificationIcon
