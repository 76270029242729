import React from 'react'

const AvatarIcon = ({ type }) => {
  if (type === 'dark') {
    return (
      <svg
        width='36'
        height='36'
        viewBox='0 0 36 36'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='18' cy='18' r='18' fill='#334155' />
        <path
          d='M23.8641 14.2836C23.8641 17.3522 21.4556 19.8399 18.4844 19.8399C15.5132 19.8399 13.1047 17.3522 13.1047 14.2836C13.1047 11.215 15.5132 8.72754 18.4844 8.72754C21.4556 8.72754 23.8641 11.215 23.8641 14.2836Z'
          fill='#94A3B8'
        />
        <path
          d='M23.8643 20.9316H13.1051C11.1039 20.9316 9.37694 22.0734 8.4502 23.75C10.6277 27.0754 14.3128 29.2659 18.4848 29.2659C22.6568 29.2659 26.3418 27.0754 28.5195 23.75C27.5913 22.0734 25.866 20.9316 23.8645 20.9316H23.8643Z'
          fill='#94A3B8'
        />
      </svg>
    )
  }
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='18' cy='18' r='18' fill='#CBD5E1' />
      <path
        d='M23.8641 14.2836C23.8641 17.3522 21.4556 19.8399 18.4844 19.8399C15.5132 19.8399 13.1047 17.3522 13.1047 14.2836C13.1047 11.215 15.5132 8.72754 18.4844 8.72754C21.4556 8.72754 23.8641 11.215 23.8641 14.2836Z'
        fill='white'
      />
      <path
        d='M23.8643 20.9316H13.1051C11.1039 20.9316 9.37694 22.0734 8.4502 23.75C10.6277 27.0754 14.3128 29.2659 18.4848 29.2659C22.6568 29.2659 26.3418 27.0754 28.5195 23.75C27.5913 22.0734 25.866 20.9316 23.8645 20.9316H23.8643Z'
        fill='white'
      />
    </svg>
  )
}

export default AvatarIcon
