import SuprSendInbox from '.'
import { configurationStore, notificationStore } from './store'
import { IRemoteNotification } from './types'
import { epochNow } from './utils'

export default class Inbox {
  private config: SuprSendInbox

  constructor(config: SuprSendInbox) {
    this.config = config
  }

  private _overallFirstApiCall() {
    const notifStore = notificationStore.getState()

    for (let storeId in notifStore.stores) {
      const store = notifStore.stores[storeId]
      if (store.isFirstCall === false) {
        return false
      }
    }

    return true
  }

  async fetchNotifications() {
    const apiClient = this.config.client
    const notifStore = notificationStore.getState()
    const configStore = configurationStore.getState()

    const activeStoreId = notifStore.activeStoreId
    if (!activeStoreId || !notifStore.stores?.[activeStoreId]) return

    const storeData = notifStore.stores[activeStoreId]
    const isFirstTime = storeData.isFirstCall
    const initialFetchTime = notifStore.initialFetchTime || Date.now()

    const storeQuery = this.config.stores?.find((store) => {
      return store.storeId === activeStoreId
    })

    try {
      if (!apiClient || !notifStore.hasNext) return

      if (isFirstTime) {
        notificationStore.setState({ initialLoading: true })

        if (this._overallFirstApiCall()) {
          this.getNotificationsCount()
        }
      } else if (notifStore.pageNumber > 1) {
        notificationStore.setState({ fetchMoreLoading: true })
      }

      this.config.emitter.emit('sync_notif_store')

      const response = await apiClient.getNotifications(
        storeQuery,
        notifStore.pageNumber,
        configStore.pageSize,
        initialFetchTime
      )
      const data = await response.json()
      let newNotifications

      if (isFirstTime || (!isFirstTime && notifStore.pageNumber === 1)) {
        newNotifications = data.results
      } else {
        newNotifications = [...storeData.notifications, ...data.results]
      }

      notifStore.stores[activeStoreId].notifications = newNotifications
      notifStore.stores[activeStoreId].isFirstCall = false

      notificationStore.setState({
        stores: notifStore.stores,
        pageNumber: notifStore.pageNumber + 1,
        hasNext: data.meta.current_page < data.meta.total_pages,
        initialFetchTime: initialFetchTime,
        initialLoading: false,
        fetchMoreLoading: false
      })

      this.config.emitter.emit('sync_notif_store')
      this.config._startExipryCheck()
    } catch (e) {
      console.log('SUPRSEND: error getting latest notifications', e)
      notificationStore.setState({
        initialLoading: false,
        fetchMoreLoading: false
      })
      this.config.emitter.emit('sync_notif_store')
    }
  }

  async getNotificationsCount() {
    const apiClient = this.config.client

    if (!apiClient) return

    try {
      const response = await apiClient.getNotificationsCount()
      const data = await response.json()

      notificationStore.setState((prevState) => {
        for (let storeId in prevState.stores) {
          const store = prevState.stores[storeId]
          store.unseenCount = data[storeId] || 0
        }
        return {
          unseenCount: data.ss_bell_count,
          stores: { ...prevState.stores }
        }
      })

      this.config.emitter.emit('sync_notif_store')
    } catch (e) {
      console.log('SUPRSEND: error getting notifications count', e)
    }
  }

  async markClicked(id: string) {
    const apiClient = this.config.client
    const notifStore = notificationStore.getState()
    const activeStoreId = notifStore.activeStoreId

    if (!activeStoreId || !notifStore.stores?.[activeStoreId]) return

    const storeData = notifStore.stores[activeStoreId]
    const notifications: IRemoteNotification[] = storeData.notifications

    const clickedNotification: IRemoteNotification | undefined =
      notifications.find((item: IRemoteNotification) => item.n_id === id)

    if (
      clickedNotification &&
      (!clickedNotification.interacted_on || !clickedNotification.seen_on)
    ) {
      if (!apiClient) return

      try {
        if (!clickedNotification.interacted_on) {
          apiClient.markNotificationClicked(id)
        } else if (!clickedNotification.seen_on) {
          apiClient.markNotificationRead(id)
        }

        const clickedOn = epochNow()
        clickedNotification.interacted_on = clickedOn

        if (!clickedNotification.seen_on) {
          for (let storeId in notifStore?.stores) {
            const store = notifStore.stores[storeId]
            store.notifications?.forEach((item) => {
              if (item.n_id === clickedNotification.n_id) {
                item.seen_on = clickedOn
                item.interacted_on = clickedOn
                if (store.unseenCount > 0) {
                  store.unseenCount -= 1
                }
              }
            })
          }
        }
        notificationStore.setState({ ...notifStore })
        this.config.emitter.emit('sync_notif_store')
      } catch (e) {
        console.log('SUPRSEND: error marking notification clicked', e)
      }
    }
  }

  async markAllSeen() {
    const apiClient = this.config.client

    try {
      if (!apiClient) return

      apiClient.markBellClicked()

      notificationStore.setState({ unseenCount: 0 })
      this.config.emitter.emit('sync_notif_store')
    } catch (e) {
      console.log('SUPRSEND: error marking all notifications seen', e)
    }
  }

  async markAllRead() {
    const apiClient = this.config.client

    try {
      if (!apiClient) return

      apiClient.markAllRead()

      const notifStore = notificationStore.getState()
      const clickedOn = epochNow()

      for (let storeId in notifStore.stores) {
        const store = notifStore.stores[storeId]
        store.notifications.forEach((notification: IRemoteNotification) => {
          if (!notification.seen_on) {
            notification.seen_on = clickedOn
          }
        })
        store.unseenCount = 0
      }

      notificationStore.setState({ stores: notifStore.stores })
      this.config.emitter.emit('sync_notif_store')
    } catch (e) {
      console.log('SUPRSEND: error marking all notifications read', e)
    }
  }

  async markRead(id: string) {
    const apiClient = this.config.client
    const notifStore = notificationStore.getState()
    const activeStoreId = notifStore.activeStoreId

    if (!activeStoreId || !notifStore.stores?.[activeStoreId]) return

    const storeData = notifStore.stores[activeStoreId]
    const notifications: IRemoteNotification[] = storeData.notifications

    const clickedNotification: IRemoteNotification | undefined =
      notifications.find((item: IRemoteNotification) => item.n_id === id)

    if (clickedNotification && !clickedNotification.seen_on) {
      if (!apiClient) return
      try {
        apiClient.markNotificationRead(id)

        const clickedOn = epochNow()

        if (!clickedNotification.seen_on) {
          for (let storeId in notifStore?.stores) {
            const store = notifStore.stores[storeId]
            store.notifications?.forEach((item) => {
              if (item.n_id === clickedNotification.n_id) {
                item.seen_on = clickedOn
                if (store.unseenCount > 0) {
                  store.unseenCount -= 1
                }
              }
            })
          }
        }
        notificationStore.setState({ ...notifStore })
        this.config.emitter.emit('sync_notif_store')
      } catch (e) {
        console.log('SUPRSEND: error marking notification read', e)
      }
    }
  }

  async markUnRead(id: string) {
    const apiClient = this.config.client
    const notifStore = notificationStore.getState()
    const activeStoreId = notifStore.activeStoreId

    if (!activeStoreId || !notifStore.stores?.[activeStoreId]) return

    const storeData = notifStore.stores[activeStoreId]
    const notifications: IRemoteNotification[] = storeData.notifications

    const clickedNotification: IRemoteNotification | undefined =
      notifications.find((item: IRemoteNotification) => item.n_id === id)

    if (clickedNotification && clickedNotification.seen_on) {
      if (!apiClient) return
      try {
        apiClient.markNotificationUnRead(id)

        if (clickedNotification.seen_on) {
          for (let storeId in notifStore?.stores) {
            const store = notifStore.stores[storeId]
            store.notifications?.forEach((item) => {
              if (item.n_id === clickedNotification.n_id) {
                item.seen_on = undefined
                if (store.unseenCount >= 0) {
                  store.unseenCount += 1
                }
              }
            })
          }
        }
        notificationStore.setState({ ...notifStore })
        this.config.emitter.emit('sync_notif_store')
      } catch (e) {
        console.log('SUPRSEND: error marking notification read', e)
      }
    }
  }

  markArchived(id: string) {
    const apiClient = this.config.client
    const notifStore = notificationStore.getState()
    const activeStoreId = notifStore.activeStoreId

    if (!activeStoreId || !notifStore.stores?.[activeStoreId]) return

    const stores = notifStore?.stores
    const storeData = stores[activeStoreId]

    const clickedNotification: IRemoteNotification | undefined =
      storeData.notifications.find(
        (item: IRemoteNotification) => item.n_id === id
      )

    if (clickedNotification && !clickedNotification.archived) {
      if (!apiClient) return
      try {
        apiClient.markNotificationArchive(id)

        storeData.notifications = storeData.notifications.filter(
          (notification) => notification.n_id !== id
        )

        if (!clickedNotification.seen_on && storeData.unseenCount > 0) {
          storeData.unseenCount -= 1
        }

        notificationStore.setState({ ...notifStore })
        this.config.emitter.emit('sync_notif_store')
      } catch (e) {
        console.log('SUPRSEND: error marking notification archived', e)
      }
    }
  }

  get stores() {
    return this.config.stores
  }

  get data() {
    const notifStore = notificationStore.getState()
    const activeStoreId = notifStore.activeStoreId
    const storeFilters = this.stores

    if (!activeStoreId) return

    return {
      unseenCount: notifStore.unseenCount, // for bell
      activeStoreId: notifStore.activeStoreId,
      stores: notifStore.stores,
      hasStores: storeFilters ? storeFilters?.length > 0 : false,
      hasNext: notifStore.hasNext,
      initialLoading: notifStore.initialLoading,
      fetchMoreLoading: notifStore.fetchMoreLoading
    }
  }
}
